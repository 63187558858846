<template>
  <div class="content-page">
    <div class="content-nav">
      <el-breadcrumb class="breadcrumb" separator="/">
        <el-breadcrumb-item>管理员</el-breadcrumb-item>
      </el-breadcrumb>
      <!-- <div class="operation-nav">
				<router-link to="/dashboard/user">
					<el-button type="primary" icon="plus">添加管理员</el-button>
				</router-link>
			</div> -->
    </div>
    <div class="content-main">
      <div class="form-table-box">
        <el-table :data="tableData" style="width: 100%" border stripe>
          <el-table-column prop="company" label="公司名称"></el-table-column>
          <el-table-column prop="account" label="登录账户"></el-table-column>

          <el-table-column prop="角色" label="管理员">
             <template scope="scope">
              <el-button
                type="primary"
                size="small"
                >管理员</el-button
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" width="180">
            <template scope="scope">
              <el-button size="small" type="danger" @click="handleRowEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: 1,
      total: 0,
      filterForm: {
        username: ''
      },
      tableData: [],
      loginInfo: null
    };
  },
  methods: {
    handleRowEdit(index, row) {
      this.$router.push({ name: 'admin_add', query: { id: row.id } });
    },
    
    onSubmitFilter() {
      this.page = 1;
      this.getList();
    },
    getList() {
      this.axios.get('admin').then((response) => {
        this.tableData = response.data.data;
        console.log(this.tableData);
      });
    }
  },
  components: {},
  mounted() {
    this.getList();
    if (!this.loginInfo) {
      this.loginInfo = JSON.parse(window.localStorage.getItem('userInfo') || null);
    }
  }
};
</script>

<style scoped></style>
